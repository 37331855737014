import React from "react";
import "../App.css";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer-section">
      <div className="footer-container"> 
            <div className="logo-footer text-center">
                <a href="/" onClick={scrollToTop}>
                    <img
                    src="https://educdn.s3.us-east-2.amazonaws.com/usadegreehelp/usadegreehelp_logo_transparent.png"
                    alt="USADegreeHelp Logo"
                    />
                </a>
            </div>
            
            <div className="disclosure-message">
            <p>
                <strong>Disclosure:</strong> Usadegreehelp.com receives compensation for the featured schools on our websites (see “Sponsored
                Schools” or “Sponsored Listings” or “Sponsored Results”). So what does this mean for you? Compensation may
                impact where the Sponsored Schools appear on our websites, including whether they appear as a match through
                our education matching services tool, the order in which they appear in a listing, and/or their ranking. Our
                websites do not provide, nor are they intended to provide, a comprehensive list of all schools (a) in the
                United States (b) located in a specific geographic area or (c) that offer a particular program of study. By
                providing information or agreeing to be contacted by a Sponsored School, you are in no way obligated to
                apply to or enroll with the school.
            </p>
            </div>

            <div className="footer-text text-center">
            2023 © USADegreeHelp. All Rights Reserved.
            </div>
      </div>
    </footer>
  );
}

import React from "react";
import { Navbar, Container } from "react-bootstrap";
import "../App.css";

export default function Header() {
  return (
    <Navbar bg="white" expand="lg" className="shadow">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="https://educdn.s3.us-east-2.amazonaws.com/usadegreehelp/usadegreehelp_logo.png"
            alt="USADegreeHelp Logo"
            className="img-fluid maxHeightLogo"
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

import React from "react";
import "../App.css";

export default function Process() {
  return (
    <>
        <div className="gradient-section">
            <div className="container-section2">
                <div className="gradient-content">
                    <div className="inner-container">
                        <div className="column-container">
                            <div className="icon">
                                <img src="https://educdn.s3.us-east-2.amazonaws.com/usadegreehelp/phone-icon.jpg" alt="phone-icon" width={35}/>
                            </div>
                            <div className="text-process">Enter your number and submit.</div>
                        </div>
                        <div className="divider"></div>
                        <div className="column-container">
                            <div className="icon">
                                <img src="https://educdn.s3.us-east-2.amazonaws.com/usadegreehelp/phone-link-icon.jpg" alt="phone-link-icon" width={35}/>
                            </div>
                            <div className="text-process">Click the link from your SMS to proceed.</div>
                        </div>
                        <div className="divider"></div>
                        <div className="column-container">
                            <div className="icon">
                                <img src="https://educdn.s3.us-east-2.amazonaws.com/usadegreehelp/book-icon.jpg" alt="book-icon" width={35}/>
                            </div>
                            <div className="text-process">Outline your academic preferences and interests.</div>
                        </div>
                        <div className="divider"></div>
                        <div className="column-container">
                            <div className="icon">
                                <img src="https://educdn.s3.us-east-2.amazonaws.com/usadegreehelp/grad-cap-icon.jpg" alt="grad-cap-icon" width={35}/>
                            </div>
                            <div className="text-process">Find your perfect college match!</div>
                        </div>
                    </div>
                </div>

                <div className="additional-text text-center">
                    <p>
                        *By clicking the 'Submit' button, I state that I have read and understood{" "}
                        <a href="https://qualfon.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                            USADegreeHelp’s Privacy Policy
                        </a>.
                    </p>
                    <p>
                        Interested in reaching out to the USADegreeHelp team?{" "}
                        <a href="mailto:info@usadegreehelp.com" rel="noopener noreferrer">
                            Contact us here.
                        </a>
                    </p>
                </div>

            </div>
        </div>
        
    </>
  );
}

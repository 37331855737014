import React, { useState, useEffect } from "react";
import "../App.css";
import axios from "axios";
import { toast } from 'react-toastify';
import Process from "./Process";
import { useSearchParams } from "react-router-dom";

export default function Home() {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [error, setError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [reqid, setReqid] = useState("");
    const [affid, setAffid] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setAffid(searchParams.get('affid'))
    setReqid(searchParams.get('reqid'));
  }, [searchParams]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);

    // Validate phone number
    const validFormat = /^\d*$/.test(value);

    if (value.length >= 11) {
      setError("Please enter a valid 10-digit US phone number.");
    } else {
      setError(validFormat ? "" : "Please enter a valid US phone number.");
    }
  };

  const handleFormSubmit = () => {
    if (!error) {
      // Proceed with form submission
      const url = `https://mydegreeadmin.com/api/filterPhone?phone_number=${phoneNumber}&reqid=${reqid}&affid=${affid}`;
      
      const headers = {
        'X-API-Key': '9c0a5d54-a174-49d0-b18f-5e90cd10a41f'
      };

      axios.post(url, { headers })
        .then(response => {

          // Show a notification on successful API response
          if (response.data && response.data.success === true) {
            toast.success("Thank You For Your Time!");
            setSubmitted(true);

          } else {
            setError("Please try again later.");
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {

      console.error('Invalid phone number');
    }

  };

  return (
    <>
      <div className="hero-section">
        <div className="container">
          {!submitted ? (
            <div className="hero-content">
              <div className="text-content">
                <h1>Embark on Your Academic Journey:</h1>
                <h1>Find the Perfect College for You!</h1>
              </div>
              <div className="image-content">
                <img className="hero-image"
                  src="https://educdn.s3.us-east-2.amazonaws.com/usadegreehelp/usadegreehelp_hero_img.png"
                  alt="Academic Journey"
                />
              </div>
            </div>
          ) : (
            <div className="thank-you-section">
              <div className="thank-you-box">
                <h1>Thank You!</h1>
                <p>Your number has been submitted successfully.</p>
                <p>What's Next?</p>
                <p>Simply click on the link in the SMS we’ve sent you.</p>
              </div>
            </div>
          )}

          {(!submitted || error) && (
            <div className="error-message">
              <span>{error}</span>
            </div>
          )}

          {!submitted && (
            <div className="input-content">
              <input
                type="tel"
                pattern="[0-9]*"
                placeholder="Enter your number"
                value={phoneNumber}
                onChange={handleInputChange}
              />
              <button className="submit-btn" type="submit" onClick={handleFormSubmit}>Submit</button>
            </div>
          )}
        </div>
      </div>

      {!submitted && <Process />}

    </>

  );
}

import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <>
      <div className="App">
         <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
        ></ToastContainer>

          <Header />

           <Routes>
            <Route path="/" element={<Home />} />
          </Routes>

          <Footer />
        
      </div>
    </>
  );
}

export default App;
